import React from "react";
import { Link } from "gatsby";

const PostLink = ({ post }) => {
  if (post.frontmatter) {
    return (
      <div className="p-4 pt-6 border-b border-grey-lighter">
        <Link to={post.frontmatter.path} className="block no-underline">
          <span className="font-bold no-underline text-blue-darker text-lg">
            {post.frontmatter.title}
          </span>{" "}
          <div className="text-grey-darker text-base">{post.excerpt}</div>
        </Link>
        <div className="text-grey-darker text-xs mt-2">
          <Link className="no-underline" to="/about">
            <span className="font-semibold text-teal-dark">
              {post.frontmatter.author}
            </span>{" "}
          </Link>
          - <span className="inline-block mr-6">{post.frontmatter.date}</span>
          {post.frontmatter.tags.map(tag => {
            return (
              <Link to={`tag/${tag}`} className="no-underline" key={`${tag}`}>
                <span className="p-1 bg-grey-lighter mr-1 text-grey-darkest font-medium">
                  {tag}
                </span>
              </Link>
            );
          })}
        </div>
      </div>
    );
  } else if (post.json.metadata.frontmatter) {
    const frontmatter = post.json.metadata.frontmatter;
    return (
      <div className="p-4 pt-6 border-b border-grey-lighter">
        <Link to={frontmatter.path} className="block no-underline">
          <span className="font-bold no-underline text-blue-darker text-lg">
            {frontmatter.title}
          </span>{" "}
          {/* <div className="text-grey-darker text-base">{post.excerpt}</div> */}
        </Link>
        <div className="text-grey-darker text-xs mt-2">
          <Link className="no-underline" to="/about">
            <span className="font-semibold text-teal-dark">
              {frontmatter.author}
            </span>{" "}
          </Link>
          - <span className="inline-block mr-6">{frontmatter.date}</span>
          {frontmatter.tags.map(tag => {
            return (
              <Link to={`tag/${tag}`} className="no-underline" key={`${tag}`}>
                <span className="p-1 bg-grey-lighter mr-1 text-grey-darkest font-medium">
                  {tag}
                </span>
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
};

export default PostLink;
