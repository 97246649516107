import React from "react";
import PostLink from "../components/post-link";
import { graphql } from "gatsby";
import Layout from "../components/layout";
const _ = require("lodash");

const IndexPage = ({
  data: {
    allMarkdownRemark: mEdges,
    allJupyterNotebook: nEdges,
    notebookTags: { group: nTagList },
    tags: { group: tagList }
  },
  location: { pathname }
}) => {
  if (mEdges) {
    const Posts = mEdges.edges
      .concat(nEdges.edges)
      // sort the combined noetbooks and blog posts in reverse chronological order.
      .sort((a, b) => {
        const aDate =
          (a.node.frontmatter && a.node.frontmatter.date) ||
          a.node.json.metadata.frontmatter.date;
        const bDate =
          (b.node.frontmatter && b.node.frontmatter.date) ||
          b.node.json.metadata.frontmatter.date;
        return a > b ? 1 : -1;
      })
      // .filter(edge => !!edge.node.frontmatter.date)
      .map(edge => <PostLink key={edge.node.id} post={edge.node} />);

    // const Notebooks = nEdges.edges.map(edge => (
    //   <PostLink key={edge.node.id} post={edge.node} />
    // ));
    return (
      <Layout tags={_.uniq(tagList.concat(nTagList))} path={pathname}>
        <div>
          {Posts}
          {/* {Notebooks} */}
        </div>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <div>No posts here.</div>
      </Layout>
    );
  }
};

export default IndexPage;

export const PageQuery = graphql`
  query IndexQuery($path: String!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { site: { eq: $path } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            author
            tags
          }
        }
      }
    }

    allJupyterNotebook(
      sort: { order: DESC, fields: [metadata___frontmatter___date] }
    ) {
      edges {
        node {
          json {
            metadata {
              frontmatter {
                path
                title
                tags
                author
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    }

    notebookTags: allJupyterNotebook(limit: 2000) {
      group(field: metadata___frontmatter___tags) {
        fieldValue
      }
    }

    tags: allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { published: { ne: false } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`;
